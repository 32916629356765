import React from 'react'
import { motion } from 'framer-motion';
import CongratesPNG from '../../assets/congratulationPopup.png';
import { useNavigate } from 'react-router-dom';

const CongratulationModal = ({ agencyName, phoneNumber }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/');
    };
    return (
        <div>
            <motion.div className="fixed inset-0 bg-black bg-opacity-70" />
            <motion.div className="p-6 z-10 fixed inset-0 flex justify-center items-center">
                <div className=' px-4 py-6 md:px-20 bg-white shadow-lg md:py-20 rounded-md'>
                    <div className='flex flex-col   justify-center items-center '>
                        <div className=' w-16 h-16 md:w-24 md:h-24' >
                            <img
                                className="w-full h-full object-contain"
                                src={CongratesPNG}
                                alt="logo"
                            />


                        </div>
                        <div className=' text-2xl md:text-3xl pt-sans-bold pb-2'>Congratulations</div>
                        <div className='flex '>

                        </div>
                        <div className='text-[15px] pt-sans-regular text-center'> Your contact details have been successfully shared with
                            {agencyName && (
                                <span className='text-[15px] pt-sans-regular '> <span className='underline'>“{agencyName}”</span></span>

                            )} </div>

                    </div>
                    <div className="py-2 ">
                        <button
                            onClick={handleGoBack}

                            className="bg-green-400 text-black rounded-full pt-sans-bold  py-2 w-full text-[14px]"
                        >
                            Go Back
                        </button>
                    </div>
                    {phoneNumber && (
                        <>
                        <div className='text-[15px] pt-sans-regular text-center text-gray-500'>You can contact the consultant directly on</div>
                        <div className='underline text-center text-[15px] pt-sans-regular'>+91 {phoneNumber}</div>
                        </>
                    )}
                </div>




            </motion.div>
        </div>

    )
}

export default CongratulationModal