import React, { useEffect, useState, useCallback } from 'react';
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import CourseFilter from '../tabs/CourseFilter';
import { useNavigate } from 'react-router-dom';
import { useDropdown } from '../DropdownContext/DropdownContext';






const Countries = ({ educationCount, totalPages, previousPage, setCurrentPage, nextPage, currentPage, countries, fetchCountries, setEducationTypeCounts, educationTypeCounts, count, setSelectedEducationType, selectedEducationType, loading, feesTo, feesFrom, marks }) => {
    const [cardCount, setCardCount] = useState(0);
    const { setSelectedCountry } = useDropdown();
    const [educationName, setEducationName] = useState('');



    // Calculate the number of shimmer placeholders based on the screen size
    const calculateCardCount = () => {
        const width = window.innerWidth;
        if (width >= 1024) {
            // lg screens
            return 3 * 3; // 3 rows x 3 columns
        } else if (width >= 768) {
            // md screens
            return 2 * 3; // 2 rows x 3 columns
        } else {
            // mobile screens
            return 1 * 3; // 1 row x 3 columns
        }
    };

    useEffect(() => {
        setCardCount(calculateCardCount());

        // Update card count on window resize
        const handleResize = () => {
            setCardCount(calculateCardCount());
        };
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const navigate = useNavigate();
    useEffect(() => {
        // Retrieve the selectedEducationType from localStorage
        const selectedEducationType = JSON.parse(localStorage.getItem('selectedEducationType'));
        if (selectedEducationType && selectedEducationType.name) {

            if (selectedEducationType.name === 'Masters')
                setEducationName('Bachelors');
            else if (selectedEducationType.name === 'Bachelors')
                setEducationName('+2/HSE');
            else if (selectedEducationType.name === 'Diploma')
                setEducationName('+2/HSE');
            else if (selectedEducationType.name === 'PHD/masters')
                setEducationName('Masters');

        }
    }, [selectedEducationType]);


    // const handleCountryClick = (countryId, countryData) => {
    //     setSelectedCountry(countryData);
    //     console.log('Selected Country:', countryData); // Debugging line
    //     navigate(`/course?countryId=${countryId}`, { state: { countryData } });
    // };


    // const handleCountryClick = (countryId, countryData) => {
    //     setSelectedCountry(countryData);

    //     // Use URLSearchParams to build the query string
    //     const queryParams = new URLSearchParams({
    //         countryId,
    //         marks,

    //         feesTo
    //     }).toString();

    //     // Navigate to the course page with the query parameters
    //     navigate(`/course?${queryParams}`, { state: { countryData, marks,  feesTo } });
    //     console.log(marks, feesFrom, feesTo,"hello marks");

    // };

    const handleCountryClick = (countryId, countryData) => {
        // Ensure default values are used if state is undefined
        const effectiveMarks = marks !== undefined ? marks : '';
        const effectiveFeesTo = feesTo !== undefined ? feesTo : '';

        setSelectedCountry(countryData);

        const queryParams = new URLSearchParams({
            countryId,
            marks: effectiveMarks,
            feesTo: effectiveFeesTo,
        }).toString();

        navigate(`/course?${queryParams}`, { state: { countryData, marks: effectiveMarks, feesTo: effectiveFeesTo } });

    };






    const handleNextPage = () => {
        if (nextPage) {
            fetchCountries(nextPage, selectedEducationType);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleBackPage = () => {
        if (previousPage) {
            fetchCountries(previousPage, selectedEducationType);
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleEducationTypeChange = (e) => {
        setSelectedEducationType(e.target.value);
    };
    const educationType = selectedEducationType?.id;


    if (loading) {
        return (
            <div className='md:px-16'>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 px-10'>
                    {Array.from({ length: cardCount }).map((_, index) => (
                        <div key={index}
                        >
                            <ShimmerSimpleGallery row={1} col={1} card imageHeight={120} caption />



                        </div>
                    ))}
                </div>
                <div className='flex gap-2 py-6 items-center justify-center'>
                    {previousPage && (
                        <button
                            onClick={handleBackPage}
                            className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'
                        >
                            BACK
                        </button>
                    )}
                    <div className='text-black text-[12px] pt-sans-bold font-bold'>
                        {currentPage} of {totalPages}
                    </div>
                    {nextPage && (
                        <button
                            onClick={handleNextPage}
                            className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'
                        >
                            NEXT
                        </button>
                    )}
                </div>
            </div>
        );
    }





    return (
        <div>
            <div className='bg-white'>

            </div>
            <div className='md:px-16'>



                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
                    {countries.map((country, index) => {


                        // Get education count for the selected education type
                        const educationLevelCount = country.education_level_count.find(
                            (item) => item.id === parseInt(educationType)
                        );

                        const educationCount = educationLevelCount ? educationLevelCount.count : 'No data';
                        return (
                            <div key={index} className="block shadow-md rounded-md py-2.5  mx-4 md:mx-0 hover:: shadow-lg hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 " onClick={() => handleCountryClick(country.id, country)} style={{ cursor: 'pointer' }}>
                                <div className="px-2.5 flex flex-col justify-between gap-4">
                                    <div className='relative'>
                                        <img className='rounded-md' src={country.image || 'Example.png'} alt={country.name} />
                                        <div className=' absolute bottom-2 right-2 inter-bold text-[12px] bg-white text-green-600 rounded-full px-2 py-1'>{educationCount} Courses</div>
                                    </div>

                                    <div className="">

                                        <div className='flex justify-between items-center'>
                                            <div className='flex flex-col py-2 w-full '>
                                                <div className='flex  flex-col gap-2 '>
                                                    <div className='flex  justify-between'>
                                                        <div className='flex  gap-2 items-center'>
                                                            <div className='flex flex-col '>
                                                                <div className='w-8 h-8 rounded-full'>
                                                                    <img className='w-full h-full rounded-full' src={country.flag || 'default-image.png'} alt='Country' />

                                                                </div>


                                                            </div>

                                                            <div className='flex flex-col'>
                                                                <div className='inter-bold font-bold text-[16px] md:text-lg'>{country.usps && country.usps.length > 0 ? country.usps[0].country.name : 'Unknown Country'}</div>

                                                            </div>

                                                        </div>
                                                        <div className='flex gap-1 items-center'>
                                                            {country.language_requirements && country.language_requirements.map((lang, langIndex) => (
                                                                <span key={langIndex} className='text-[12px] pt-sans-bold '>
                                                                    {lang.name}{langIndex < country.language_requirements.length - 1 && ' / '}
                                                                </span>
                                                            ))}
                                                        </div>


                                                    </div>


                                                    <div>
                                                        <div className='pt-sans-regular text-[16px] md:text-[15px] '>Minimum <span className='pt-sans-bold'>{country.minimum_mark} </span> % mark required in <span className='pt-sans-bold'>{educationName}</span> for {selectedEducationType.name} admission </div>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='w-3 h-3 rounded-full'>
                                                            <img className='w-full h-full rounded-full' src='./money.png' alt='Country' />

                                                        </div>
                                                        <div className='text-[15px] md:text-[14px] pt-sans-regular text-blue-500 tracking-wider '>Fees start at {country.minimum_fee_per_year} Lakhs per year*
                                                        </div>



                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div className='flex gap-2 flex-wrap'>
                                            {country.usps.map((usp, uspIndex) => (
                                                <div key={uspIndex} className=' pt-sans-bold text-[12px] border  border-black px-4 py-1.5 rounded-full flex justify-center '>
                                                    {usp.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        );

                    })}
                </div>

                <div className='flex gap-2 py-6 items-center justify-center'>
                    {previousPage && (
                        <button onClick={handleBackPage} className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'>
                            BACK
                        </button>
                    )}
                    <div className='text-black text-[12px] pt-sans-bold font-bold'>{currentPage} of {totalPages}</div>
                    {nextPage && (
                        <button onClick={handleNextPage} className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'>
                            NEXT
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Countries;
