
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance/axiosInstance';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function OAuthRedirectHandler() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');

        if (!code) {
            console.error('Authorization code not found in URL.');
            navigate('/error');
            setLoading(false);
            return;
        }
        console.log('Authorization code:', code);
        axiosInstance.get(`account/api/v1/auth/google/callback/?code=${code}`)
            .then(response => {
                console.log('Response from Google callback:', response);
                const { access_token } = response.data;
                console.log('Access token received:', access_token);
                if (access_token) {
                   
                    console.log('Sending access token to backend:', access_token);
                    axiosInstance.post('account/api/v1/google_login/', { access_token })
                        .then(response => {
                            console.log('Response from your backend:', response);
                            const { access, refresh,user } = response.data || {};

                            if (access && refresh) {
                                console.log('Tokens received, storing in localStorage');
                                // Store the application's tokens in local storage
                                localStorage.setItem('access', access);
                                localStorage.setItem('refresh', refresh);
                                localStorage.setItem('user_info', JSON.stringify(user));

                                // Redirect to the home page
                                navigate('/');
                            } else {
                                console.error('Tokens not found in backend response');
                                navigate('/error');
                            }
                        })
                        .catch(error => {
                            console.error('Error during backend login:', error.response ? error.response.data : error.message);
                            navigate('/error');
                        });
                } else {
                    console.error('Access token not found in Google callback response');
                    navigate('/error');
                }
            })
            .catch(error => {
                console.error('Error during OAuth process:', error.response ? error.response.data : error.message);
                navigate('/error');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [location, navigate]);

    return (
        <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
      >
        {loading ? <CircularProgress /> : <CircularProgress />}
      </Box>
    );
}

export default OAuthRedirectHandler;
