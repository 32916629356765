
import React, { useState, useEffect } from 'react';
import { useDropdown } from '../DropdownContext/DropdownContext'; 
import { useLocation } from 'react-router-dom'; 
import axiosInstance from '../axiosInstance/axiosInstance';
import { NavLink } from 'react-router-dom';

const CourseFilter = ({ setSelectedEducationType, selectedEducationType, educationTypeCounts,setEducationTypeCounts  }) => {
    const [filterApplied, setFilterApplied] = useState(false);
    const { setSelectedTab } = useDropdown();
    const location = useLocation();
    const {countryData,marks,feesTo} =location.state || {}
    // console.log(countryData,"Hello Country")

    // const[educationTypeCounts,setEducationTypeCounts]=useState([]);
     const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            country: params.get('countryId') === 'null' || !params.get('countryId') ? '' : params.get('countryId'),
            marks: params.get('marks') === 'null' || !params.get('marks') ? '' : params.get('marks'),
            fees_to: params.get('feesTo') === 'null' || !params.get('feesTo') ? '' : params.get('feesTo')
        };
    };
    const fetchEducationCounts = async () => {
        const { country, marks, fees_to } = getQueryParams();

        // Check if all parameters are empty
        const isNoFiltersApplied = !country && !marks && !fees_to;

        try {
            const response = await axiosInstance.get(`/home/api/v1/education-level-count/`, {
                params: isNoFiltersApplied
                    ? { country: '', university: '', marks: '', fees_to: '' } // Default parameters for all counts
                    : { country, marks, fees_to }, // Filtered parameters
            });
            setEducationTypeCounts(response.data);
           
            // Update state with fetched data
        } catch (error) {
            console.error('Error fetching education counts:', error);
        }
    };

    useEffect(() => {
        fetchEducationCounts();
    }, [location.search]);
    useEffect(() => {
        if (Array.isArray(educationTypeCounts) && educationTypeCounts.length > 0) {
            const defaultEducationType = location.state?.selectedEducationType || 'Masters'; 
            const eduType = educationTypeCounts.find(type => type.name === defaultEducationType);
            if (eduType && (!selectedEducationType || Object.keys(selectedEducationType).length === 0)) {
                setSelectedEducationType(eduType);
                setSelectedTab(eduType.name);
                setFilterApplied(true);
            }
        }
    }, [educationTypeCounts, setSelectedEducationType, setSelectedTab, selectedEducationType, location.state]);

    // useEffect(() => {
    //     // Set default "Master" type if available and nothing is selected
    //     if (Array.isArray(educationTypeCounts) && educationTypeCounts.length > 0) {
    //         const masterType = educationTypeCounts.find((type) => type.name === 'Masters');
    //         if (masterType && (!selectedEducationType || Object.keys(selectedEducationType).length === 0)) {
    //             setSelectedEducationType(masterType);
    //             setSelectedTab(masterType.name);
    //             setFilterApplied(true);
    //         }
    //     }
    // }, [educationTypeCounts, setSelectedEducationType, setSelectedTab, selectedEducationType]);
    const handleTabClick = (eduType) => {
        if (selectedEducationType.id === eduType.id) {
            // Toggle the filter off if the same type is clicked
            setSelectedEducationType({}); // Clear the selection
            setFilterApplied(false);
            setSelectedTab(null);
        } else {
            // Apply the filter
            setSelectedEducationType(eduType); // Set the selected education type
            setSelectedTab(eduType.name); // Update the selected tab
            setFilterApplied(true);
        }
    };

    

    return (
        <div>
            {/* <div className='py-0 md:py-4 block md:hidden'>
                <div className='block md:hidden '>
                    <div className='flex flex-col gap-4 md:gap-0 bg-[#101727] md:flex-row md:justify-between overflow-x-auto'>
                        <div className='flex flex-nowrap'>
                            <div className="flex gap-2  text-white    pt-sans-bold text-[14px] items-center whitespace-nowrap">
                                {(educationTypeCounts || []).map((eduType) => (
                                    <div
                                        key={eduType.id} 
                                        className={`cursor-pointer text-[16px] md:text-[16px] pb-2 px-2   ${selectedEducationType.id === eduType.id ? 'font-bold border-b-4 border-green-500 text-green-500' : 'text-white'}`}
                                        onClick={() => handleTabClick(eduType)}
                                    >
                                        {eduType.name} ({eduType.count})
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='  ml-1 mb-1 text-sm px-2  block  md:hidden'> 

                <span className='text-slate-500 pt-sans-regular'><NavLink to={'/'} >Home</NavLink> </span>
                <span ><NavLink className={({isActive})=>isActive? null:'text-slate-500 pt-sans-regular'} to={"/home"}>{" > "+selectedEducationType.name }</NavLink></span>
                <span className='pt-sans-regular text-slate-700'>{countryData ? " > " + countryData?.name:null}</span>
                
            </div>
           <div className='pt-sans-bold text-[20px] pt-2 ml-1 block px-2  md:hidden '>{location.pathname == '/course'? selectedEducationType ?.name + " in " +countryData?.name : selectedEducationType ?.name }</div> 
           <div className='px-2 pb-2 ml-1 text-[15px]  md:hidden'>
           {selectedEducationType?.name==="Masters"?"Post Graduate Programs that include PG & PG Diplomas":null}
           {selectedEducationType?.name==="Bachelors"? "including bachelor's degrees and bachelor's diplomas.":null}
           

           </div>
            <div className="hidden md:block">
                <div className="flex flex-row bg-black px-1 w-[400px] rounded-full py-1 items-center justify-between my-4">
                    {(educationTypeCounts || []).map((eduType) => (
                        <div
                            key={eduType.id} 
                            className={`text-[12px] py-1.5 px-2 whitespace-nowrap cursor-pointer transition-all duration-500 linear ${selectedEducationType.id === eduType.id
                                ? 'bg-green-400 rounded-full text-black font-semibold px-4'
                                : 'text-white font-normal'
                                }`}
                            onClick={() => handleTabClick(eduType)}
                        >
                            {eduType.name} ({eduType.count})
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CourseFilter;







