

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import tokenInstance from '../axiosInstance/tokenInstance';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { useDropdown } from '../DropdownContext/DropdownContext';
import GroupPNG from '../../assets/Group.png';

const AgencyFilter = ({ closeModal, countryData, courseId, marks, feesTo, selectedAgencyName }) => {

    const { selectedCourse } = useDropdown();
    const { selectedCountry } = useDropdown();
    const { selectedTab } = useDropdown();


    const [formData, setFormData] = useState({
        marks: marks || 100,
        // fees_from: feesFrom || 0,
        fees_to: feesTo || 50,
        name: "",
        lastName: "",
        mobile: "",
        email: "",

        agency: 1, // Assuming agency is provided or selected
    });

    const [errors, setErrors] = useState({});
    const [filterId, setFilterId] = useState(null);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [educationLevels, setEducationLevels] = useState([]);
    const [serverMessage, setServerMessage] = useState('');
    const [clientExistError, setClientExistError] = useState('');


    console.log(countryData, "country selected data");



    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const response = await tokenInstance.get(`home/api/v1/student-course-detail/${courseId}/`);
                if (response.status === 200) {
                    const courseData = response.data;

                    // Update form data with course details
                    setFormData({
                        ...formData,
                        agency: courseData.agency.user_info.id
                            || 1,  // Set the agency from course data
                    });
                }
            } catch (error) {
                console.error('Error fetching course details:', error);
            }
        };

        if (courseId) {
            fetchCourseDetails();
        }
    }, [courseId]);

    useEffect(() => {
        const fetchEducationLevels = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/course-levels/');
                setEducationLevels(response.data);
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };
        fetchEducationLevels();
    }, []);
    console.log('Payload country:', selectedCountry);

    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/view-student-filter/');
                if (response.status === 200) {
                    const filterData = response.data;
                    setFilterId(filterData.id);
                    setFormData({
                        marks: filterData.marks,
                        // fees_from: filterData.fees_from,
                        fees_to: filterData.fees_to,
                        name: filterData.name || "",
                        lastName: filterData.lastName || "",
                        mobile: filterData.mobile || "",
                        email: filterData.email || "",
                        education_level: filterData.education_level || 0,
                        agency: filterData.agency || 1, // Set default agency ID
                    });
                    setIsSuccessful(true);
                }
            } catch (error) {
                console.error('Error fetching filter data:', error);
                setIsSuccessful(false);
            }
        };
        fetchFilterData();
    }, []);

    // const handleMarksChange = (event, newValue) => {
    //     setFormData({ ...formData, marks: newValue });
    // };
    const handleMarksChange = (event, newValue) => {
        // setFormData({ ...formData, marks: newValue });
        setFormData((prevData) => ({ ...prevData, marks: newValue }));

    };

    // const handleFeesChange = (event, newValue) => {
    //     setFormData({ ...formData, fees_from: newValue[0], fees_to: newValue[1] });
    // };
    const handleFeesChange = (event, newValue) => {

        setFormData((prevData) => ({ ...prevData, fees_to: newValue }));

    };



    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
        }
    };

    const handleEducationLevelChange = (event, newValue) => {
        setFormData({ ...formData, education_level: newValue });
    };

    // Function to validate if the agency and mobile already exist
    const validateAgencyAndMobile = async () => {
        try {
            const response = await tokenInstance.post('kanban/api/v1/home_client_validate/', {
                agency: formData.agency,
                mobile: formData.mobile
            });

            if (response.status === 409) { // HTTP status code check
                const errorMessage = response.data.error || 'Mobile number already exists'; // Default error message
                const newErrors = { ...errors, mobile: errorMessage };
                setErrors(newErrors);
                setClientExistError('Client already exists.'); // Set the client existence error
                console.log('Errors after validation:', newErrors); // Debugging line
                return false; // Validation failed
            }
            // Clear mobile error if validation is successful
            setErrors((prevErrors) => ({ ...prevErrors, mobile: undefined }));
            setClientExistError(''); // Clear the client existence error
            return true; // Validation passed
        } catch (error) {
            console.error('Error validating agency and mobile:', error);
            setErrors((prevErrors) => ({ ...prevErrors, mobile: 'This number is already exist.' })); // Set a general error message
            setClientExistError('Client is already exists.'); // Set the client existence error
            return false;
        }
    };



    const validateForm = () => {
        let formErrors = {};

        if (!formData.name) formErrors.name = "First name is required";
        if (!formData.lastName) formErrors.lastName = "Last name is required";
        if (!formData.mobile) formErrors.mobile = "Phone number is required";
        if (!formData.email) formErrors.email = "Email is required";
        if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (formData.mobile && !/^\d{10}$/.test(formData.mobile)) {
            formErrors.mobile = "Phone number must be 10 digits";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0; // True if no errors
    };

    const handleSaveFilter = async () => {
        if (!validateForm()) {
            return; // Stop the submission if validation fails
        }

        // Validate agency and mobile before saving
        const isValid = await validateAgencyAndMobile();
        if (!isValid) return; // Stop if validation fails
        const countryId = countryData.id;
        console.log(countryId ,"countryData");
        

        const payload = {
            name: formData.name + formData.lastName,
            mobile: formData.mobile,
            email: formData.email,
            marks: formData.marks,
            preferred_country: countryId,
            budget: `Between ${formData.fees_from}-${formData.fees_to} Lakhs`,
            agency: formData.agency,
        };

        try {
            if (!filterId) {
                const response = await tokenInstance.post('kanban/api/v1/home_client_create/', payload);
                console.log('Client created:', response.data);
                setFilterId(response.data.id);
                setServerMessage('Client created successfully!');
            } else {
                const response = await tokenInstance.patch(`kanban/api/v1/update-student-filter/${filterId}/`, payload);
                console.log('Filter updated:', response.data);
                setServerMessage('Filter updated successfully!');
            }
            setIsSuccessful(true);
            closeModal();
        } catch (error) {
            console.error('Error saving filter:', error);
            setServerMessage('Client already exists.');
            setIsSuccessful(false);
        }
    };
    const formatFees = (value) => {
        return `${value} lakhs`;
    };

    return (
        <div>
            <motion.div className="fixed inset-0 bg-black bg-opacity-70" />
            <motion.div className="p-6 z-10 fixed inset-0 flex justify-center items-center">
                <div className='bg-white  w-[400px] h-auto px-6 py-2  rounded-sm overflow-y-auto md:overflow-y-hidden'>
                    <div className='relative '>

                        <div className="flex justify-between items-center py-4 md:py-0 md:pt-6 ">
                            <div className='flex gap-2'>
                                <div className="w-[25px] overflow-hidden">
                                    <img
                                        className="w-full h-full object-contain"
                                        src={GroupPNG}
                                        alt="logo"
                                    />
                                </div>
                                <div className="font-bold text-2xl text-black ">norvel</div>

                            </div>

                            <button
                                onClick={closeModal}
                                className=' text-[15px] flex items-center justify-center bg-gray-100 rounded-full w-6 h-6'>
                                <CloseIcon fontSize='small' />
                            </button>
                        </div>
                        <div className='flex flex-col pt-2 pb-6'>

                            <div className='pt-sans-bold text-[13px] text-center py-2'>For admissions and Enquiry, Please share your
                                contact details with <span className='underline textshadow-lg'>{selectedAgencyName}</span></div>


                            <div className='flex flex-col gap-2  md:pt-2'>
                                <div>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="First Name"
                                        className="border border-gray-300 pt-sans-regular rounded-sm py-2 w-full text-[12px] text-black outline-none px-4"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                    {errors.name && <span className="text-red-500 text-[12px] pt-sans-regular">{errors.name}</span>}
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        className="border border-gray-300 rounded-sm pt-sans-regular py-2 w-full text-[12px] text-black outline-none px-4"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                    {errors.lastName && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.lastName}</span>}
                                </div>
                                <div>
                                    <input
                                        type="tel"
                                        name="mobile"
                                        placeholder="Phone"
                                        className="border border-gray-300 rounded-sm pt-sans-regular py-2 w-full text-[12px] px-4 outline-none"
                                        value={formData.mobile}
                                        onChange={handleInputChange}
                                    />
                                    {errors.mobile && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.mobile}</span>}
                                </div>
                                <div>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className="border border-gray-300 rounded-sm pt-sans-regular py-2 w-full text-[12px] px-4 outline-none"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.email}</span>}
                                </div>
                            </div>

                            {/* <div className='pb-4 py-6'>
                            <div className='text-[13px] pt-sans-bold pb-2'>Select Education Level</div>
                            <Box>
                                <Slider
                                    defaultValue={formData.education_level}
                                    aria-label="Education Level"
                                    onChange={handleEducationLevelChange}
                                    value={formData.education_level}
                                    min={0}
                                    max={educationLevels.length - 1}
                                    marks={educationLevels.map((level, index) => ({
                                        value: index,
                                        label: (
                                            <span style={{ width: '40px', fontSize: '10px' }}>
                                                {level.name}
                                            </span>
                                        ),
                                    }))}
                                />
                            </Box>
                        </div> */}

                            <div className='pb-4 py-4 md:py-6'>
                                <div className='text-[13px] pt-sans-bold pb-2'>Your Marks in 12th</div>
                                <Box>
                                    <Slider
                                        defaultValue={formData.marks}
                                        aria-label="Marks"
                                        valueLabelDisplay="auto"
                                        sx={{
                                            color: 'black',
                                            '& .MuiSlider-thumb': {
                                                borderRadius: '50%',
                                                backgroundColor: 'black',
                                                border: '2px solid black',
                                            },
                                            '& .MuiSlider-track': {
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-rail': {
                                                backgroundColor: '#bfbfbf',
                                            },
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderRadius: '4px',
                                            },
                                        }}
                                        onChange={handleMarksChange}
                                        value={formData.marks}
                                    />
                                </Box>
                                <div className='flex justify-between '>
                                    <div className='pt-sans-regular text-[12px]'>0%</div>                                    <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                    <div className='pt-sans-regular text-[12px]'>100%</div>                                </div>
                            </div>

                            <div className='pb-4 py-4 md:py-6'>
                                <div className='text-[13px] pt-sans-bold pb-2'>Fees Range</div>
                                <Box>
                                    <Slider
                                        value={formData.fees_to}
                                        onChange={handleFeesChange}
                                        valueLabelDisplay="auto"
                                        min={0}
                                        max={20}
                                        sx={{
                                            color: 'black',
                                            '& .MuiSlider-thumb': {
                                                borderRadius: '50%',
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-track': {
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-rail': {
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderRadius: '4px',
                                            },
                                        }}
                                    />

                                </Box>
                                <div className='flex justify-between '>
                                    <div className='pt-sans-regular text-[12px]'>0 lakhs</div>
                                    <div className='pt-sans-bold text-[12px]'>{` ${formatFees(formData.fees_to)}`}</div>
                                    <div className='pt-sans-regular text-[12px]'>50 lakhs</div>
                                </div>
                            </div>

                            {clientExistError && <span className="text-red-500 text-sm mb-2">{clientExistError}</span>}
                            {serverMessage && (
                                <div className={`text-sm ${isSuccessful ? 'text-green-600' : 'text-red-600'}`}>
                                    {serverMessage}
                                </div>
                            )}

                            <div className="py-2">
                                <button
                                    onClick={handleSaveFilter}
                                    className="bg-green-500 text-black pt-sans-bold rounded-sm py-2 w-full text-[12px]"
                                >
                                    Save
                                </button>
                            </div>
                            {/* <button
                                onClick={closeModal}
                                className='hidden md:flex absolute top-5 right-5 text-[15px]  items-center justify-center bg-gray-100 rounded-full w-6 h-6'>
                                <CloseIcon fontSize='small' />
                            </button> */}
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default AgencyFilter;
