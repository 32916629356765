

import axios from 'axios';

export const baseURL = 'https://norvel.site/';

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

// Request interceptor to remove authorization header for login API
axiosInstance.interceptors.request.use(config => {
  if (config.url.includes('/')) {
    console.log("login url");
    delete config.headers.Authorization;
    localStorage.removeItem('token');
  }
  return config;
});

// Response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response: { status } } = error;

    if (status === 401) {
      // Handle unauthorized error (401) here if needed
      console.error('Unauthorized access - perhaps redirect to login?');
      // window.location.href = '/login'; // Uncomment this line if you want to redirect to login page
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;




