
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import tokenInstance from '../axiosInstance/tokenInstance';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { AnimatePresence } from 'framer-motion';

import GroupPNG from '../../assets/Group.png';

import { useLocation } from 'react-router-dom';
import CongratulationModal from './CongratulationModal';





const AdmissionPartner = () => {
    const location = useLocation();
    const [courseDetails, setCourseDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMarksSlider, setShowMarksSlider] = useState(false);
    const [showFeesSlider, setShowFeesSlider] = useState(false);
    const [isSliderActive, setIsSliderActive] = useState(false);
    const [showCongratsModal, setShowCongratsModal] = useState(false)
    const [agencyName, setAgencyName] = useState("")
    const [agencyID, setAgencyID] = useState("")
    const [educationName, setEducationName] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const [courseid, setCourseid] = useState('')
    useEffect(() => {
        // Retrieve the selectedEducationType from localStorage
        const selectedEducationType = JSON.parse(localStorage.getItem('selectedEducationType'));
        if (selectedEducationType && selectedEducationType.name) {

            if (selectedEducationType.name === 'Masters')
                setEducationName('Bachelors');
            else if (selectedEducationType.name === 'Bachelors')
                setEducationName('+2');
            else if (selectedEducationType.name === 'Diploma')
                setEducationName('+2');
            else if (selectedEducationType.name === 'Phd / Research')
                setEducationName('Masters');

        }
    }, []);








    // Get parameters from URL
    const urlParams = new URLSearchParams(location.search);
    const countryId = urlParams.get('countryId');
    const marks = urlParams.get('marks');
    // const feesFrom = urlParams.get('feesFrom');
    const feesTo = urlParams.get('feesTo');
    const courseId = urlParams.get('courseId');





    // const universityId = course?.u

    const [formData, setFormData] = useState({
        marks: marks || 0,
        // fees_from: feesFrom || 0,
        fees_to: feesTo || 0,
        name: "",
        lastName: "",
        mobile: "",
        email: "",
        comment: "",


    });

    const [errors, setErrors] = useState({});
    const [filterId, setFilterId] = useState(null);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [educationLevels, setEducationLevels] = useState([]);
    const [serverMessage, setServerMessage] = useState('');
    const [clientExistError, setClientExistError] = useState('');
    const [showSlider, setShowSlider] = useState(false);
    const [secondSlider, setSecondSlider] = useState(false);
    const [title, setTitle] = useState("Masters");
    const [isClosing, setIsClosing] = useState(false);
    const [feesClosing, setFeesClosing] = useState(false);
    const [validationError, setValidationError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("")
    const [minimumMark, setMinimumMark] = useState("");
    const [minimumFees, setMinimumFees] = useState("");
    const toggleSlider = () => {
        if (showSlider) {
            setIsClosing(true); // Start the closing animation
            setTimeout(() => {
                setShowSlider(false); // Hide slider after animation completes
                setIsClosing(false); // Reset closing state
            }, 300); // Match the transition duration in CSS
        } else {
            setShowSlider(true); // Open slider immediately
        }
    };
    const toggleSecondSlider = () => {
        if (secondSlider) {
            setFeesClosing(true); // Start the closing animation
            setTimeout(() => {
                setSecondSlider(false); // Hide slider after animation completes
                setFeesClosing(false); // Reset closing state
            }, 300); // Match the transition duration in CSS
        } else {
            setSecondSlider(true); // Open slider immediately
        }

    }
    const toggleCheck = () => {
        setIsChecked(!isChecked);
        setErrors("");  // Toggle the state
    };




    const fetchCourseDetails = async (id) => {
        try {
            const response = await tokenInstance.get(`/home/api/v1/student-course-detail/${id}/`);
            const data = response.data || {}; // Ensure data has a fallback object
            console.log("API Response:", data); // Log full API response for debugging
    
            // Extract language requirements, ensuring it's always an array
            const languageRequirements = Array.isArray(data.language_requirements) ? data.language_requirements : [];
            
            // Set agency-related fields with optional chaining
            setAgencyName(data.agency?.user_info?.first_name);
            setAgencyID(data.agency?.user);
            setPhoneNumber(data.agency?.phone);
            setMinimumFees(data.
                fee_per_year
                )
    
            // Log minimum_mark value from API before setting it in state
            console.log("Minimum Mark from API:", data.minimum_mark);
    
            // Set minimum_mark in state with a fallback value
            setMinimumMark(data.minimum_mark ?? "Default Value"); // Replace "Default Value" as needed
    
            // Set course details while ensuring required fields have default values
            setCourseDetails({
                ...data,
                language_requirements: languageRequirements, // Always an array
            });
        } catch (err) {
            console.error("Error fetching course details:", err); // Log error for debugging
            setError("Failed to fetch course details");
        } finally {
            setIsLoading(false); // Stop loading spinner or indicator
        }
    };
    



    // const fetchCourseDetails = async (id) => {
    //     try {
    //         const response = await tokenInstance.get(`/home/api/v1/student-course-detail/${id}/`);
    //         // Ensure the response has the expected structure
    //         const data = response.data || {};
    //         console.log("API Response:", response.data);


    //         const languageRequirements = Array.isArray(data.language_requirements) ? data.language_requirements : [];
    //         setAgencyName(data.agency?.user_info?.first_name)
    //         setAgencyID(data.agency?.user)
    //         setPhoneNumber(data.agency.phone)

    //         console.log("Minimum Mark from API:", data.minimum_mark);

    //     // Set minimum_mark in state with a fallback value
    //     setMinimumMark(data.minimum_mark ?? "Default Value"); 



    //         // Set the course details with fallback values for undefined fields
    //         setCourseDetails({
    //             ...data,
    //             language_requirements: languageRequirements,  // Ensure it's always an array
    //         });
    //     } catch (err) {
    //         setError('Failed to fetch course details');
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    useEffect(() => {
        if (courseId) {
            fetchCourseDetails(courseId);
        }
    }, [courseId]);

    useEffect(() => {
        const fetchEducationLevels = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/course-levels/');
                setEducationLevels(response.data);
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };
        fetchEducationLevels();
    }, []);


    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/view-student-filter/');
                if (response.status === 200) {
                    const filterData = response.data;
                    setFilterId(filterData.id);
                    setFormData({
                        marks: filterData.marks,
                        // fees_from: filterData.fees_from,
                        fees_to: filterData.fees_to,
                        name: filterData.name || "",
                        lastName: filterData.lastName || "",
                        mobile: filterData.mobile || "",
                        email: filterData.email || "",
                        comment: filterData.comment || "",
                        education_level: filterData.education_level || 0,
                        agency: filterData.agency || 1, // Set default agency ID
                    });
                    setIsSuccessful(true);
                }
            } catch (error) {
                console.error('Error fetching filter data:', error);
                setIsSuccessful(false);
            }
        };
        fetchFilterData();
    }, []);

    // const handleMarksChange = (event, newValue) => {
    //     setFormData({ ...formData, marks: newValue });
    // };


    // const handleFeesChange = (event, newValue) => {
    //     setFormData({ ...formData, fees_from: newValue[0], fees_to: newValue[1] });
    // };




    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        }
    };

    const handleEducationLevelChange = (event, newValue) => {
        setFormData({ ...formData, education_level: newValue });
    };

    // Function to validate if the agency and mobile already exist
    const validateAgencyAndMobile = async () => {
        try {
            const response = await tokenInstance.post('kanban/api/v1/home_client_validate/', {
                agency: formData.agency,
                mobile: formData.mobile
            });

            if (response.status === 409) { // HTTP status code check
                const errorMessage = response.data.error || 'Mobile number already exists'; // Default error message
                const newErrors = { ...errors, mobile: errorMessage };
                setErrors(newErrors);
                setClientExistError('Client already exists.'); // Set the client existence error
                console.log('Errors after validation:', newErrors); // Debugging line
                return false; // Validation failed
            }
            // Clear mobile error if validation is successful
            setErrors((prevErrors) => ({ ...prevErrors, mobile: undefined }));
            setClientExistError(''); // Clear the client existence error
            return true; // Validation passed
        } catch (error) {
            console.error('Error validating agency and mobile:', error);
            setErrors((prevErrors) => ({ ...prevErrors, mobile: 'This number is already exist.' })); // Set a general error message
            setClientExistError('Client is already exists.'); // Set the client existence error
            return false;
        }
    };



    const validateForm = () => {
        let formErrors = {};

        if (!formData.name) formErrors.name = "First name is required";

        if (!formData.mobile) {
            formErrors.mobile = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            formErrors.mobile = "Phone number must be 10 digits";
        }

        if (!isChecked) {
            formErrors.terms_and_conditions = "You must accept the terms and conditions";
        }
        if (!formData.marks) {
            formErrors.marks = "Please select your marks";
        } else {
            const minimumMarkNumber = Number(minimumMark); // Convert to number
            if (isNaN(minimumMarkNumber)) {
                formErrors.marks = "Minimum mark is not valid.";
            } else if (formData.marks < minimumMarkNumber) {
                formErrors.marks = `For admission,minumum ${minimumMarkNumber} % required.`;
            }
        }


        // Fees validation: Show error only if fees_to is null, 'null', or 50
        if (!formData.fees_to) {
            formErrors.fees_to = "Please select fees you can afford";
        } else {
            const minimumFeesNumber = Number(minimumFees); // Convert minimumFees to a number
            if (isNaN(minimumFeesNumber)) {
                formErrors.fees_to = "Minimum budget is not valid.";
            } else if (formData.fees_to < minimumFeesNumber) {
                formErrors.fees_to = `This course required ${minimumFees} Lakhs for study.`;
            }
        }
    

        setErrors(formErrors); // Update state with errors

        // Return true if no errors, otherwise false
        return Object.keys(formErrors).length === 0;
    };


    const handleSaveFilter = async () => {
        if (!validateForm()) {
            return; // Stop the submission if validation fails
        }

        // Validate agency and mobile before saving
        const isValid = await validateAgencyAndMobile();
        if (!isValid) return; // Stop if validation fails


        const payload = {
            name: formData.name + formData.lastName,
            mobile: formData.mobile,
            email: formData.email,
            comment: formData.comment,
            marks: formData.marks,
            preferred_country: countryId,
            budget: `Between ${formData.fees_from}-${formData.fees_to} Lakhs`,
            agency: agencyID,
            terms_conditions: isChecked,
            course_id: courseId,

        };

        try {
            let response;
            if (!filterId) {
                response = await tokenInstance.post('kanban/api/v1/home_client_create/', payload);
                console.log('Client created:', response.data);
                setFilterId(response.data.id);
                setServerMessage('Client created successfully!');
            } else {
                response = await tokenInstance.patch(`kanban/api/v1/update-student-filter/${filterId}/`, payload);
                console.log('Filter updated:', response.data);
                setServerMessage('Filter updated successfully!');
            }
            if (response.status === 201) {
                setShowCongratsModal(true); // Show the modal
            }
            setIsSuccessful(true);
            // closeModal();
        } catch (error) {
            console.error('Error saving filter:', error);
            setServerMessage('Client already exists.');
            setIsSuccessful(false);
        }
    };

    const formatFees = (value) => {
        return `${value} lakhs`;
    };
    const handleMarksChangeCommitted = (event, newValue) => {
        setFormData((prev) => ({ ...prev, marks: newValue }));
    };

    const handleFeesChangeCommitted = (event, newValue) => {
        setFormData((prev) => ({ ...prev, fees_to: newValue }));
    };


    const handleFeesChange = (event, newValue) => {
        setFormData((prevData) => ({ ...prevData, fees_to: newValue }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.fees_to; // Remove the 'fees_to' error
            return updatedErrors;
        });
    };

    const handleMarksChange = (event, newValue) => {
        setFormData((prevData) => ({ ...prevData, marks: newValue }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.marks; // Remove the 'marks' error
            return updatedErrors;
        });
    };

    console.log(typeof formData.fees_to, typeof minimumFees);
    console.log("minimumFees:", minimumFees);
    console.log("Form Data Marks:", formData.marks);
    

    return (

        <div className='px-0 md:px-20'>
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-10 md:pt-2 '>




                <div className='hidden md:block'>
                    <div className='text-[13px] pt-sans-bold px-6 pb-4'>University Details</div>

                    {courseDetails ? (
                        <div className="bg-white shadow-md cursor-pointer border border-gray-300 "  >
                            <div className="flex flex-col justify-between gap-4 px-2 py-2">
                                <div className="flex flex-col md:flex-row justify-between">

                                    <div className='w-full'>
                                        <div style={{
                                            // backgroundImage: 'url(/web2.png)',
                                            backgroundImage: `url(${courseDetails.university.banner_image
                                                })`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '150px',
                                        }} className='rounded-md'>
                                            <div className="flex  justify-between item-center">
                                                <div className='flex items-center py-4 px-4 gap-4'>
                                                    <img src={courseDetails.university.logo_image} alt={`${courseDetails.university.name} Logo`} className="w-12 h-12 object-cover rounded-full" /> {/* Adjust width and height as needed */}
                                                    <div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className='text-[12px] pt-sans-bold pb-1 pt-4 '>{courseDetails.university?.name}<span className='text-[12px] pt-sans-bold '>- Private</span></div>

                                        <div className='pt-sans-regular text-[11px] '>{courseDetails.campus}, {courseDetails.country?.name}</div>
                                        <div className=' py-4  flex flex-col   justify-between '>
                                            <div className='flex flex-col pb-2'>
                                                <div className='text-[13px] pt-sans-bold text-green-600
                                                             '> Admission open</div>
                                                <div className='pt-sans-bold text-xl'>{courseDetails.name}</div>
                                            </div>

                                            {/* <div className='bg-green-400 py-1.5 md:py-0 md:h-8 px-4  rounded-full flex justify-center items-center  gap-1  cursor-pointer  ' onClick={() => handleContactAgency(course.agency?.user_info?.first_name)} >

                                        <div className='text-[11px] whitespace-nowrap  inter-semilight'> Contact Agency</div>
                                    </div> */}

                                        </div>
                                        <div class="border-dotted-b   flex items-center justify-center block "></div>
                                        <div className='grid grid-cols-3 gap-4 pt-2 sm:grid-cols-3 md:grid-cols-3'>
                                            <div>
                                                <div className='pt-sans-regular text-[12px]'> Duration</div>
                                                <div className='pt-sans-bold text-[12px]'>{courseDetails.course_duration} Years </div>



                                            </div>
                                            <div>
                                                <div className='pt-sans-regular text-[12px]'>POF</div>
                                                <div className='pt-sans-bold text-[12px] '>{courseDetails.POF}Lakhs({courseDetails.POF_mode}) </div>
                                            </div>
                                            <div>
                                                <div className='pt-sans-regular text-[12px]'>Fees</div>
                                                <div className='pt-sans-bold text-[12px]'>INR {courseDetails.fee_per_year}LPA </div>
                                            </div>
                                            <div>
                                                <div className='pt-sans-regular text-[12px]'>Language</div>
                                                <div className='pt-sans-bold text-[12px]'><span className='flex gap-1 items-center pt-sans-bold'>

                                                    {/* {Array.isArray(courseDetails.language_requirements) && courseDetails.language_requirements.length > 0 ? (
                                                        <>
                                                            {courseDetails.language_requirements.map((requirement, index) => (
                                                                <div className='flex' key={index}>
                                                                    <span className='pt-sans-bold text-[11px] flex'>

                                                                        {requirement.language_requirement}

                                                                        {requirement.language_requirement !== 'MOI' && ` - ${requirement.score}`}

                                                                        {index < courseDetails.language_requirements.length - 1 && ' / '}
                                                                    </span>
                                                                </div>
                                                            ))}

                                                        </>
                                                    ) : (
                                                        'N/A' // Fallback if language_requirements is empty or undefined
                                                    )} */}
                                                </span>

                                                    <div className='pt-sans-bold text-[12px]'> {courseDetails?.university?.language_pref} </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div className='pt-sans-regular text-[12px]'>Fees</div>
                                                <div className='pt-sans-bold text-[12px]'>INR {courseDetails.fee_per_year}LPA </div>
                                            </div>
                                            <div>
                                                <div className='pt-sans-regular text-[12px]'>Intake</div>
                                                <div className='pt-sans-bold text-[12px]'> {courseDetails.intake} </div>
                                            </div>






                                        </div>
                                    </div>

                                </div>

                                <div class="hidden md:flex border-dotted-b  items-center justify-center "></div>
                                <div className="flex justify-between pb-4">
                                    <div className="flex gap-2.5 flex-wrap">
                                        {courseDetails.tags && Array.isArray(courseDetails.tags) && courseDetails.tags.length > 0 ? (
                                            courseDetails.tags.map(tag => (
                                                <div key={tag.id}>
                                                    <span className="inter-bold text-[10px] border border-black px-6 rounded-full py-1.5">{tag.tag}</span>
                                                </div>
                                            ))
                                        ) : (
                                            <span>No tags available</span> // Or any fallback text you prefer
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <p>No course details available</p>
                    )}
                </div>




                <div>
                    <div className='text-[13px] pt-sans-bold px-6  md:pb-4 hidden md:block'>Admission Partner</div>


                    <div className='bg-white  md:shadow-lg  h-auto md:px-2 md:py-2  rounded-sm overflow-y-auto md:overflow-y-hidden border border-gray-300'>
                        <div className=' '>

                            {/* <div> bg image */}
                            <div
                                className=" h-40 py-4"
                                style={{
                                    backgroundImage: `url(${courseDetails?.agency?.banner_image ?? '/web2.png'})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                }}
                            >
                            </div>


                            <div className='flex flex-col gap-4 pt-2 px-4 md:px-0'>

                                <span className='pt-sans-regular text-[18px] md:[text-14px] text-center py-2 px-14'>For admissions and Enquiry, Please share your
                                    contact details with <span className='underline textshadow-lg pt-sans-bold'>{courseDetails?.agency?.user_info?.first_name}</span></span>


                                <div className='flex flex-col gap-2   '>
                                    <div className='grid grid-cols-1  gap-2 sm:grid-cols-1 lg:grid-cols-2  '>
                                        <div>
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="First Name"
                                                className="border border-gray-300 pt-sans-regular rounded-sm py-2 w-full text-[15px] lg:text-[13px] text-black outline-none px-4"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.name && <span className="text-red-500 text-[13px]  pt-sans-regular">{errors.name}</span>}
                                        </div>
                                        {/* <div>
                                            <input
                                                type="text"
                                                name="lastName"
                                                placeholder="Last Name"
                                                className="border border-gray-300 rounded-sm pt-sans-regular py-2 w-full text-[12px] text-black outline-none px-4"
                                                value={formData.lastName}
                                                onChange={handleInputChange}
                                            />
                                            {errors.lastName && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.lastName}</span>}
                                        </div> */}
                                        <div>
                                            <input
                                                type="tel"
                                                name="mobile"
                                                placeholder="Phone"
                                                className="border border-gray-300 rounded-sm pt-sans-regular py-2 w-full text-[15px] lg:text-[13px] px-4 outline-none"
                                                value={formData.mobile}
                                                onChange={handleInputChange}
                                            />
                                            {errors.mobile && <span className="text-red-500   text-[13px] pt-sans-regular">{errors.mobile}</span>}
                                        </div>

                                    </div>



                                    {/* <div>
                                        <input
                                            type="tel"
                                            name="mobile"
                                            placeholder="Phone"
                                            className="border border-gray-300 rounded-sm pt-sans-regular py-2 w-full text-[12px] px-4 outline-none"
                                            value={formData.mobile}
                                            onChange={handleInputChange}
                                        />
                                        {errors.mobile && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.mobile}</span>}
                                    </div>
                                    <div>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className="border border-gray-300 rounded-sm pt-sans-regular py-2 w-full text-[12px] px-4 outline-none"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                        {errors.email && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.email}</span>}
                                    </div> */}
                                </div>

                                {/* <div className='pb-4 py-6'>
                            <div className='text-[13px] pt-sans-bold pb-2'>Select Education Level</div>
                            <Box>
                                <Slider
                                    defaultValue={formData.education_level}
                                    aria-label="Education Level"
                                    onChange={handleEducationLevelChange}
                                    value={formData.education_level}
                                    min={0}
                                    max={educationLevels.length - 1}
                                    marks={educationLevels.map((level, index) => ({
                                        value: index,
                                        label: (
                                            <span style={{ width: '40px', fontSize: '10px' }}>
                                                {level.name}
                                            </span>
                                        ),
                                    }))}
                                />
                            </Box>
                        </div> */}

                                {/* <div className='grid grid-cols-2  gap-2'>
                                    <div className='pb-4 py-4 md:py-6'>
                                        <div className='text-[13px] pt-sans-bold pb-2'>Your Marks in 12th</div>
                                        <Box>
                                            <Slider
                                                defaultValue={formData.marks}
                                                aria-label="Marks"
                                                valueLabelDisplay="auto"
                                                sx={{
                                                    color: 'black',
                                                    '& .MuiSlider-thumb': {
                                                        borderRadius: '50%',
                                                        backgroundColor: 'black',
                                                        border: '2px solid black',
                                                    },
                                                    '& .MuiSlider-track': {
                                                        backgroundColor: 'black',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        backgroundColor: '#bfbfbf',
                                                    },
                                                    '& .MuiSlider-valueLabel': {
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        borderRadius: '4px',
                                                    },
                                                }}
                                                onChange={handleMarksChange}
                                                value={formData.marks}
                                            />
                                        </Box>
                                        <div className='flex justify-between '>
                                            <div className='pt-sans-regular text-[12px]'>0%</div>                                    <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                            <div className='pt-sans-regular text-[12px]'>100%</div>                                </div>
                                    </div>

                                    <div className='pb-4 py-4 md:py-6'>
                                        <div className='text-[13px] pt-sans-bold pb-2'>Fees Range</div>
                                        <Box>
                                            <Slider
                                                value={formData.fees_to}
                                                onChange={handleFeesChange}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={20}
                                                sx={{
                                                    color: 'black',
                                                    '& .MuiSlider-thumb': {
                                                        borderRadius: '50%',
                                                        backgroundColor: 'black',
                                                    },
                                                    '& .MuiSlider-track': {
                                                        backgroundColor: 'black',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        backgroundColor: 'black',
                                                    },
                                                    '& .MuiSlider-valueLabel': {
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        borderRadius: '4px',
                                                    },
                                                }}
                                            />

                                        </Box>
                                        <div className='flex justify-between '>
                                            <div className='pt-sans-regular text-[12px]'>0 lakhs</div>
                                            <div className='pt-sans-bold text-[12px]'>{` ${formatFees(formData.fees_to)}`}</div>
                                            <div className='pt-sans-regular text-[12px]'>50 lakhs</div>
                                        </div>
                                    </div>


                                </div> */}
                                <div className='grid grid-cols-1  gap-2 sm:grid-cols-1 lg:grid-cols-2 '>

                                    <div className=' '>
                                        <div
                                            className={`py-2.5 border border-gray-300 rounded-sm py-1 px-4  flex justify-between items-center rounded-md cursor-pointer '
                                                }`}
                                            onClick={toggleSlider}
                                        >
                                            <div className='flex justify-between w-full gap-16 '>
                                                <div className='pt-sans-regular text-gray-400 text-[15px] lg:text-[13px] whitespace-nowrap'>
                                                    Your <span className='text-gray-500'>{educationName}</span> Score is <span className='text-gray-500'>{formData.marks === null || formData.marks === 'null' ? 0 : formData.marks}% Mark</span>

                                                </div>
                                                {/* <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div> */}
                                            </div>
                                            <div>{showSlider ? <FiChevronUp style={{ color: 'gray' }} /> : <FiChevronDown style={{ color: 'gray' }} />}</div>
                                        </div>
                                        {showSlider && (
                                            <div className={` lg:w-[220px] slider-container ${isClosing ? 'closed' : ''}`}>
                                                <div className='text-[13px] pt-sans-regular pb-2'></div>
                                                <Box>
                                                    <Slider
                                                        defaultValue={formData.marks === null || formData.marks === 'null' ? 0 : formData.marks}
                                                        aria-label="Marks"
                                                        valueLabelDisplay="auto"
                                                        sx={{
                                                            color: 'black',
                                                            '& .MuiSlider-thumb': {
                                                                borderRadius: '50%',
                                                                backgroundColor: 'black',
                                                                border: '2px solid black',
                                                            },
                                                            '& .MuiSlider-track': {
                                                                backgroundColor: 'black',
                                                            },
                                                            '& .MuiSlider-rail': {
                                                                backgroundColor: '#bfbfbf',
                                                            },
                                                            '& .MuiSlider-valueLabel': {
                                                                backgroundColor: 'black',
                                                                color: 'white',
                                                                borderRadius: '4px',
                                                            },
                                                        }}
                                                        onChange={handleMarksChange}
                                                        value={formData.marks === null || formData.marks === 'null' ? 0 : formData.marks}
                                                    />
                                                </Box>
                                                <div className='flex justify-between '>
                                                    <div className='pt-sans-regular text-[12px]'>0%</div>                                    <div className='pt-sans-bold text-[12px]'> {formData.marks === null || formData.marks === 'null' ? 100 : formData.marks}%</div>
                                                    <div className='pt-sans-regular text-[12px]'>100%</div>                                </div>
                                            </div>


                                        )}
                                        {errors.marks && <div className="text-red-500   text-[13px] pt-sans-regular">{errors.marks}</div>}

                                    </div>



                                    <div className='  '>
                                        <div className={` border border-gray-300 rounded-sm py-2.5  px-2 md:px-4  flex justify-between items-center rounded-md cursor-pointer 
                                            }`} onClick={toggleSecondSlider}>
                                            <div className='flex justify-between w-full  '>
                                                <div className='pt-sans-regular text-gray-400  text-[15px] lg:text-[13px] '>Yearly fees you can afford (INR) is <span className='text-gray-500'> {formData.fees_to === null || formData.fees_to === 'null' ? 50 : formData.fees_to} lakhs</span> </div>
                                                {/* <div className='pt-sans-bold text-[12px]'>Up to {formData.fees_to} LPA</div> */}
                                            </div>
                                            <div>{secondSlider ? <FiChevronUp style={{ color: 'gray' }} /> : <FiChevronDown style={{ color: 'gray' }} />}</div>
                                        </div>
                                        {secondSlider && (
                                            <div className={` lg:w-[220px] slider-container pt-2 ${feesClosing ? 'closed' : ''}`}>
                                                {/* <div className='text-[13px] pt-sans-bold pb-2'>Fees Range</div> */}
                                                <Box>
                                                    <Slider
                                                        value={formData.fees_to === null || formData.fees_to === 'null' ? 50 : formData.fees_to}
                                                        onChange={handleFeesChange}
                                                        valueLabelDisplay="auto"
                                                        min={0}
                                                        max={50}
                                                        sx={{
                                                            color: 'black',
                                                            '& .MuiSlider-thumb': {
                                                                borderRadius: '50%',
                                                                backgroundColor: 'black',
                                                            },
                                                            '& .MuiSlider-track': {
                                                                backgroundColor: 'black',
                                                            },
                                                            '& .MuiSlider-rail': {
                                                                backgroundColor: 'black',
                                                            },
                                                            '& .MuiSlider-valueLabel': {
                                                                backgroundColor: 'black',
                                                                color: 'white',
                                                                borderRadius: '4px',
                                                            },
                                                        }}
                                                    />

                                                </Box>
                                                <div className='flex justify-between '>
                                                    <div className='pt-sans-regular text-[12px]'>0 lakhs</div>
                                                    <div className='pt-sans-bold text-[12px]'>{formData.fees_to === null || formData.fees_to === 'null' ? 0 : formData.fees_to}lakhs</div>
                                                    <div className='pt-sans-regular text-[12px]'>50 lakhs</div>
                                                </div>
                                            </div>



                                        )}
                                        {errors.fees_to && <div className="text-red-500   text-[13px] pt-sans-regular">{errors.fees_to}</div>}


                                    </div>
                                </div>


                                <div >
                                    <div >
                                        <div>
                                            <textarea
                                                name="comment"
                                                rows={4}
                                                placeholder="Anything you would like to share"
                                                className="border border-gray-300 pt-sans-regular rounded-sm py-2 resize-none w-full text-[15px] lg:text-[13px] text-black outline-none px-4"
                                                value={formData.comment}
                                                onChange={handleInputChange}
                                            />

                                        </div>
                                    </div>
                                </div>
                                {/* <div className='flex gap-2 items-center'>
                                    <div className='bg-green-400 px-1 py-1   flex items-center justify-center rounded-full'><DoneIcon style={{ fontSize: '16px', color: "white" }} /></div>

                                    <div className='pt-sans-regular text-[14px]'>I have  read the <span className='text-green-300'>Terms and Conditions</span> of Norvel.in</div>
                                </div> */}

                                <div
                                    className="flex gap-2 items-center cursor-pointer"
                                    onClick={toggleCheck}
                                >
                                    <div
                                        className={` flex items-center justify-center rounded-full ${isChecked ? "bg-green-400 border border-green-500 px-1 py-1" : "border border-green-500 px-2.5 py-2.5"
                                            }`}
                                    >
                                        {isChecked && (
                                            <DoneIcon style={{ fontSize: "16px", color: "white" }} />
                                        )}
                                    </div>

                                    <div className="pt-sans-regular text-[14px]">
                                        I have read the <span className="text-green-300">Terms and Conditions</span> of Norvel.in
                                    </div>
                                </div>
                                {errors.terms_and_conditions && (
                                    <div className="text-red-500 text-[13px]  pt-sans-regular">{errors.terms_and_conditions}</div>
                                )}


                                {clientExistError && <span className="text-red-500 text-sm mb-2">{clientExistError}</span>}
                                {serverMessage && (
                                    <div className={`text-sm ${isSuccessful ? 'text-green-600' : 'text-red-600'}`}>
                                        {serverMessage}
                                    </div>
                                )}




                                <div>
                                    <button
                                        onClick={handleSaveFilter}
                                        className="bg-green-500 text-black rounded-full pt-sans-bold  py-2 w-full text-[16px] md:text-[14px]"
                                    >
                                        Contact Now
                                    </button>
                                </div>
                                {/* <button
                                onClick={closeModal}
                                className='hidden md:flex absolute top-5 right-5 text-[15px]  items-center justify-center bg-gray-100 rounded-full w-6 h-6'>
                                <CloseIcon fontSize='small' />
                            </button> */}


                                <div className='pt-sans-regular-italic text-[13px] text-gray-400 flex flex-col pt-2 pb-6 gap-2'>
                                    <div> You Are sharing your contact details with the educational Consultant for admissions.</div>
                                    <div> Your information will be sent directly to the program provider / Educational consultant.</div>
                                    <div>  If available, we will also send you information about the requested and relevant programs and a link where you can sign up for the program directly.</div>
                                    <div>As part of our free service, we will also create an account for you in our platform, Norvel.in, where you can communicate with the program and manage your preferences.</div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>
            <AnimatePresence>
                {showCongratsModal &&
                    <CongratulationModal agencyName={agencyName} phoneNumber={phoneNumber} />




                }



            </AnimatePresence>






        </div >
    )
}

export default AdmissionPartner